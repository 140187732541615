import { LoadingSpinnerProps } from '../../sharedTypes'

const LoadingSpinner = ({
  size = 'md',
  color = 'text-gold',
  centered = false,
}: LoadingSpinnerProps) => {
  const sizeClasses = {
    sm: 'w-4 h-4',
    md: 'w-8 h-8',
    lg: 'w-12 h-12',
  }

  const spinnerStyle = centered
    ? {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }
    : {}

  return (
    <div style={spinnerStyle}>
      <div
        className={`animate-spin rounded-full border-t-2 border-b-2 ${color} ${sizeClasses[size]}`}
      ></div>
    </div>
  )
}

export default LoadingSpinner
