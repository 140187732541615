import { Question } from '../../sharedTypes'
import { useState, useCallback, useEffect } from 'react'

interface QuestionStepProps {
  question: Question
  onAnswer: (answer: string | string[]) => void
  onAutoAdvance?: () => void
  error?: string | null
  attempted?: boolean
  selectedAnswer?: string | string[]
  onNext: () => void
  onSkip?: () => void
  currentStep: number
  totalSteps: number
  onBack?: () => void
}

export const QuestionStep = ({
  question,
  currentStep,
  totalSteps,
  onAnswer,
  onAutoAdvance,
  error,
  attempted = false,
  selectedAnswer,
  onNext,
  onBack,
  onSkip,
}: QuestionStepProps) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])

  useEffect(() => {
    if (selectedAnswer) {
      setSelectedOptions(
        Array.isArray(selectedAnswer) ? selectedAnswer : [selectedAnswer],
      )
    } else {
      setSelectedOptions([])
    }
  }, [selectedAnswer, question.id])

  const handleOptionClick = useCallback(
    (option: string) => {
      if (question.type === 'multipleChoice') {
        const newSelection = [option]
        setSelectedOptions(newSelection)
        onAnswer(option)

        if (onAutoAdvance) {
          setTimeout(onAutoAdvance, 500)
        }
      } else if (question.type === 'checkbox') {
        const updatedOptions = selectedOptions.includes(option)
          ? selectedOptions.filter((item) => item !== option)
          : [...selectedOptions, option]
        setSelectedOptions(updatedOptions)
        onAnswer(updatedOptions)
      }
    },
    [question.type, selectedOptions, onAnswer, onAutoAdvance],
  )

  // Calculate progress percentage
  const progressPercentage = ((currentStep + 1) / totalSteps) * 100

  // Determine if any options are selected
  const hasSelections = selectedOptions.length > 0

  // Determine the next button text based on selection state and question requirement
  const getNextButtonText = () => {
    if (question.required) {
      return 'NEXT'
    }
    return hasSelections ? 'NEXT' : 'SKIP'
  }

  // Handle the next/skip button click
  const handleNextButtonClick = () => {
    if (!question.required && !hasSelections && onSkip) {
      onSkip()
    } else {
      onNext()
    }
  }

  return (
    <div className="flex flex-col h-full md:h-[500px] relative">
      {/* Scrollable content area */}
      <div className="flex-1 overflow-y-auto pb-32 md:pb-20">
        <div className="max-w-2xl mx-auto text-center px-4">
          {/* Question header */}
          <div className="mb-6 pt-4">
            <h2 className="text-2xl font-bold text-dark-brown mb-2">
              {question.title}
            </h2>
            <p className="text-lg text-gray-500 mb-2">{question.subtitle}</p>

            {question.required && (
              <p className="text-sm mt-1 block">
                <span className="text-gold">* Required</span>
              </p>
            )}

            {error && attempted && (
              <p className="text-red-500 mt-2 text-sm animate-fade-in">
                {error}
              </p>
            )}
          </div>

          {/* Options grid */}
          <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-3">
            {question.options?.map((option) => {
              const isSelected =
                question.type === 'checkbox'
                  ? selectedOptions.includes(option)
                  : selectedOptions[0] === option

              return (
                <button
                  key={option}
                  onClick={() => handleOptionClick(option)}
                  className={`
                    w-full p-4 rounded-lg text-lg 
                    transform transition-all duration-200 ease-out
                    ${
                      isSelected
                        ? 'bg-gold text-white shadow-lg'
                        : 'bg-light-beige text-dark-brown hover:bg-opacity-90'
                    }
                    hover:scale-[1.02]
                    active:scale-95
                    ${
                      attempted &&
                      question.required &&
                      !isSelected &&
                      !selectedOptions.length
                        ? 'border-2 border-red-300'
                        : ''
                    }
                  `}
                >
                  {option}
                </button>
              )
            })}
          </div>
        </div>
      </div>

      {/* Fixed navigation and progress section */}
      <div className="fixed md:relative bottom-0 left-0 right-0 bg-white">
        {/* Progress bar */}
        <div className="h-1 w-full bg-light-beige">
          <div
            className="h-full bg-gold transition-all duration-300 ease-out"
            style={{ width: `${progressPercentage}%` }}
          />
        </div>

        {/* Navigation buttons */}
        <div className="px-4 py-3">
          <div className="max-w-2xl mx-auto flex justify-center space-x-4">
            <button
              onClick={onBack}
              className="px-6 py-2 bg-light-beige text-dark-brown rounded-md hover:bg-opacity-90 transition duration-300"
            >
              BACK
            </button>

            <button
              onClick={handleNextButtonClick}
              className={`px-6 py-2 ${
                !question.required && !hasSelections
                  ? 'bg-light-beige text-dark-brown'
                  : 'bg-gold text-white'
              } rounded-md hover:bg-opacity-90 transition duration-300`}
            >
              {getNextButtonText()}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QuestionStep
