import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyDivQ__zRfk95HorKxLFHdf4-AvtNZ6u3A",
  authDomain: "ginger-13745.firebaseapp.com",
  projectId: "ginger-13745",
  storageBucket: "ginger-13745.appspot.com",
  messagingSenderId: "464600865506",
  appId: "1:464600865506:web:01cfbcdf2ea5e8d1df1cbb",
  measurementId: "G-G7MJ03MX6L",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const analytics = getAnalytics(app);
export const functions = getFunctions(app, "us-central1"); // Specify the region

// Helper function to log custom events
export const logAnalyticsEvent = (eventName: string, eventParams?: object) => {
  logEvent(analytics, eventName, eventParams);
};
