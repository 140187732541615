import { useState } from 'react'
import Modal from '../common/Modal'
import { useUser } from '../../hooks/useUser'
import { UpgradeOrBuyTokensModalProps } from '../../sharedTypes'
import { functions } from '../../utils/firebase'
import { httpsCallable } from 'firebase/functions'
import { useToast } from '../common/Toast'
import LoadingSpinner from '../common/LoadingSpinner'

// Price IDs from your Stripe dashboard
const STRIPE_PRICES = {
  PREMIUM: 'price_1QJd0gANwL2mtqYVgXy6Q4u5',
  PREMIUM_ANNUAL: 'price_1QK0xwANwL2mtqYV8Yi4Dgpu',
  TOKEN_1: 'price_1QJd8wANwL2mtqYVoBsWfNj5',
  TOKEN_5: 'price_1QJd8yANwL2mtqYVazoFdOiz',
  TOKEN_10: 'price_1QJd90ANwL2mtqYVqa9iG81H',
} as const

// Token products configuration
const TOKEN_PRODUCTS = {
  [STRIPE_PRICES.TOKEN_1]: { quantity: 1, price: '€2.90' },
  [STRIPE_PRICES.TOKEN_5]: { quantity: 5, price: '€5.90' },
  [STRIPE_PRICES.TOKEN_10]: { quantity: 10, price: '€12.90' },
} satisfies Record<string, { quantity: number; price: string }>

const UpgradeOrBuyTokensModal = ({
  isOpen,
  onClose,
}: UpgradeOrBuyTokensModalProps) => {
  const { user } = useUser()
  const { showToast } = useToast()
  const [loadingPriceId, setLoadingPriceId] = useState<string | null>(null)

  const isPremium = user?.membershipType === 'paid'
  const buttonClasses =
    'bg-gold text-white py-2 px-4 rounded-md hover:bg-opacity-90 transition duration-300 text-center inline-block w-full disabled:opacity-50 disabled:cursor-not-allowed'

    const handlePaymentClick = async (
      priceId: string,
      mode: 'subscription' | 'payment',
    ) => {
      setLoadingPriceId(priceId)
      try {
        const createCheckoutSession = httpsCallable<
          {
            priceId: string
            mode: 'subscription' | 'payment'
            quantity?: number
          },
          { sessionId: string; url: string }
        >(functions, 'createCheckoutSession')
    
        // Always send quantity: 1 for token purchases
        // Each price ID in Stripe should represent the full bundle (5 tokens, 10 tokens, etc.)
        const checkoutData = {
          priceId,
          mode,
          quantity: 1
        }
        console.log('Sending to Stripe:', checkoutData)
    
        const result = await createCheckoutSession(checkoutData)
        console.log('Stripe response:', result)
    
        if (result.data.url) {
          window.location.href = result.data.url
        } else {
          throw new Error('No checkout URL returned')
        }
      } catch (error) {
        console.error('Detailed error:', error)
        showToast('Failed to start checkout process', 'error')
      } finally {
        setLoadingPriceId(null)
      }
    }

  // Create a function to check if a price ID is valid
  const validatePriceId = (
    priceId: string,
  ): priceId is keyof typeof STRIPE_PRICES => {
    return Object.values(STRIPE_PRICES).includes(priceId as any)
  }

  const renderTokenButton = (priceId: keyof typeof TOKEN_PRODUCTS) => {
    // Validate price ID before rendering button
    if (!validatePriceId(priceId)) {
      return null
    }

    const product = TOKEN_PRODUCTS[priceId as keyof typeof TOKEN_PRODUCTS]
    return (
      <button
        key={priceId}
        onClick={() => handlePaymentClick(priceId, 'payment')}
        disabled={loadingPriceId === priceId}
        className={buttonClasses}
      >
        {loadingPriceId === priceId ? (
          <span className="flex items-center justify-center">
            <LoadingSpinner size="sm" color="text-white" />
            <span className="ml-2">Processing...</span>
          </span>
        ) : (
          `${product.quantity} Token${product.quantity > 1 ? 's' : ''} - ${
            product.price
          }`
        )}
      </button>
    )
  }

  // Rest of the component remains the same...
  return (
    <Modal isOpen={isOpen} onClose={onClose} title="">
      <div className="space-y-6 relative z-50">
      {!isPremium && (
  <div>
    <h3 className="text-xl font-semibold mb-2">Premium Membership</h3>
    <p className="mb-4">
      Upgrade to premium to access our extended database and AI matching functionality.
    </p>
    <div className="flex flex-col space-y-2">
      <button
        onClick={() =>
          handlePaymentClick(STRIPE_PRICES.PREMIUM, 'subscription')
        }
        disabled={loadingPriceId === STRIPE_PRICES.PREMIUM}
        className={buttonClasses}
      >
        {loadingPriceId === STRIPE_PRICES.PREMIUM ? (
          <span className="flex items-center justify-center">
            <LoadingSpinner size="sm" color="text-white" />
            <span className="ml-2">Processing...</span>
          </span>
        ) : (
          'Monthly Premium - €7.90'
        )}
      </button>
      <button
        onClick={() =>
          handlePaymentClick(STRIPE_PRICES.PREMIUM_ANNUAL, 'subscription')
        }
        disabled={loadingPriceId === STRIPE_PRICES.PREMIUM_ANNUAL}
        className={buttonClasses}
      >
        {loadingPriceId === STRIPE_PRICES.PREMIUM_ANNUAL ? (
          <span className="flex items-center justify-center">
            <LoadingSpinner size="sm" color="text-white" />
            <span className="ml-2">Processing...</span>
          </span>
        ) : (
          'Annual Premium - €59.00'
        )}
      </button>
    </div>
  </div>
)}
        <div>
          <h3 className="text-xl font-semibold mb-2">Buy Tokens</h3>
          <p className="mb-4">
            Purchase tokens to use our extended database and AI matching for
            individual searches.
          </p>
          <div className="flex flex-col space-y-2">
            {renderTokenButton(STRIPE_PRICES.TOKEN_1)}
            {renderTokenButton(STRIPE_PRICES.TOKEN_5)}
            {renderTokenButton(STRIPE_PRICES.TOKEN_10)}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default UpgradeOrBuyTokensModal
