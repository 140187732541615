import { Question } from '../../sharedTypes'

// Shared questions used in both standard and specialty searches
export const sharedQuestions: Question[] = [
  {
    id: 'mealType',
    title: 'what are you searching for?',
    subtitle: 'Select one or more options',
    type: 'checkbox',
    options: [
      'breakfast',
      'brunch',
      'lunch',
      'dinner',
      'cafe',
      'bakery/dessert',
      'tapas/drinks',
    ],
    required: true,
  },
  {
    id: 'cuisine',
    title: 'cuisine cravings?',
    subtitle: 'Select one or more options',
    type: 'checkbox',
    options: [
      'american',
      'asian',
      'italian',
      'seafood',
      'mexican',
      'fusion/modern',
      'portuguese',
      'middle eastern',
      'european',
      'other',
    ],
    required: false,
  },
  {
    id: 'priceRange',
    title: "what's the budget?",
    subtitle: 'Select one or more options',
    type: 'checkbox',
    options: ['to 10', '10-20', '20-30', '30-40', '40+'],
    required: false,
  },
  {
    id: 'neighbourhood',
    title: 'which area works best?',
    subtitle: 'Select one or more options',
    type: 'checkbox',
    options: [
      'cais do sodre, baxia, chiado',
      'principe real, praca das flores, sao bento, bairro alto',
      'alcantara, lapa, santos',
      'anjos, arroios, saldanha',
      'avenida liberdade, marques pombal, amoreiras',
      'alfama, graca, marvila',
    ],
    required: false,
  },
  {
    id: 'mood',
    title: "what's the mood you're after?",
    subtitle: 'Select one or more options',
    type: 'checkbox',
    options: ['social', 'cozy', 'casual', 'upscale'],
    required: false,
  },
  {
    id: 'dietaryPreferences',
    title: 'dietary preferences we should know about?',
    subtitle: 'Select one or more options',
    type: 'checkbox',
    options: ['vegetarian', 'vegan', 'pescatarian'],
    required: false,
  },
  {
    id: 'seating',
    title: 'do you have a preferred seating arrangement?',
    subtitle: 'Select one or more options',
    type: 'checkbox',
    options: ['inside', 'outside', 'rooftop', 'beach'],
    required: false,
  },
  {
    id: 'requirements',
    title: 'any extra requirements?',
    subtitle: 'Select one or more options',
    type: 'checkbox',
    options: ['kids', 'pets', 'acessibility'],
    required: false,
  },
]

// Additional questions for specialty search only
export const specialtyQuestions: Question[] = [
  {
    id: 'delivery',
    title: 'would you prefer to have it delivered?',
    subtitle: 'Select one option',
    type: 'multipleChoice',
    options: ['yes', 'no'],
    required: false,
  },
  {
    id: 'highProtein',
    title: 'are you interested in high-protein options?',
    subtitle: 'Select one option',
    type: 'multipleChoice',
    options: ['yes', 'no'],
    required: false,
  },
  {
    id: 'coworkAmenities',
    title: 'do you need co-work amenities?',
    subtitle: 'Select all that apply',
    type: 'checkbox',
    options: ['wifi', 'plugs', 'laptops'],
    required: false,
  },
  {
    id: 'specialtyDrinks',
    title: 'any specialty drinks in mind?',
    subtitle: 'Select all that apply',
    type: 'checkbox',
    options: ['specialty coffee', 'decaf', 'matcha', 'natural wines'],
    required: false,
  },
]

// Helper function to get questions based on search type
export const getQuizQuestions = (isSpecialtySearch: boolean): Question[] => {
  if (isSpecialtySearch) {
    return [...specialtyQuestions, ...sharedQuestions]
  }
  return sharedQuestions
}
