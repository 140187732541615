import React, { createContext, useContext, useState, useEffect } from 'react'
import {
  User,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
} from 'firebase/auth'
import { auth, googleProvider, db } from '../utils/firebase'
import LoadingSpinner from '../components/common/LoadingSpinner'
import { doc, getDoc } from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'
import { useToast } from '../components/common/Toast'

interface AuthContextType {
  user: User | null
  loading: boolean
  signInWithGoogle: (isSignUp?: boolean) => Promise<User | null>
  logout: () => Promise<void>
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}

export const AuthProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [user, setUser] = useState<User | null>(null)
  const [loading, setLoading] = useState(true)
  const { showToast } = useToast()
  const navigate = useNavigate()

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user)
      setLoading(false)
    })

    return unsubscribe
  }, [])

  const checkUserExists = async (uid: string): Promise<boolean> => {
    const userDoc = await getDoc(doc(db, 'users', uid))
    return userDoc.exists()
  }

  const signInWithGoogle = async (
    isSignUp: boolean = false,
  ): Promise<User | null> => {
    try {
      const result = await signInWithPopup(auth, googleProvider)

      // Check if user exists in our database
      const userExists = await checkUserExists(result.user.uid)

      if (!userExists && !isSignUp) {
        // If user doesn't exist and this is a sign-in attempt
        await signOut(auth) // Sign them out
        showToast('Please sign up first to create an account', 'error')
        navigate('/signup')
        return null
      }

      return result.user
    } catch (error) {
      console.error('Error in Google auth:', error)
      if (error instanceof Error && 'code' in error) {
        if (error.code === 'auth/popup-closed-by-user') {
          console.log('Sign-in popup closed by user')
        } else if (error.code === 'auth/cancelled-popup-request') {
          console.log('Another sign-in popup is already open')
        } else if (error.code === 'auth/popup-blocked') {
          console.log('Sign-in popup blocked by browser')
        }
      }
      return null
    }
  }

  const logout = async () => {
    try {
      await signOut(auth)
      navigate('/login')
    } catch (error) {
      console.error('Error signing out', error)
      showToast('Error signing out', 'error')
    }
  }

  const value = {
    user,
    loading,
    signInWithGoogle,
    logout,
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
