import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import { useToast } from '../common/Toast'
import { useEffect } from 'react'

const Login = () => {
  const { signInWithGoogle } = useAuth()
  const navigate = useNavigate()
  const { showToast } = useToast()

  // Add useEffect to handle body overflow
  useEffect(() => {
    // Disable scrolling when component mounts
    document.documentElement.style.overflow = 'hidden'
    document.body.style.overflow = 'hidden'

    // Re-enable scrolling when component unmounts
    return () => {
      document.documentElement.style.overflow = ''
      document.body.style.overflow = ''
    }
  }, [])

  const handleGoogleSignIn = async () => {
    try {
      const user = await signInWithGoogle(false) // Pass false to indicate this is a sign-in attempt
      if (user) {
        navigate('/dashboard')
      }
    } catch (error) {
      console.error('Error signing in with Google:', error)
      showToast('Error signing in', 'error')
    }
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    // TODO: Implement email/password login logic here
    // After successful login, navigate to dashboard
    // navigate('/dashboard')
  }

  return (
    <div className="h-[calc(100vh-64px)] overflow-hidden flex items-center justify-center">
      <div className="w-full max-w-md px-4 flex flex-col gap-8">
        <h2 className="text-2xl font-bold text-dark-brown mb-6 text-center">
          Welcome back
        </h2>
        <button
          onClick={handleGoogleSignIn}
          className="w-full bg-beige text-dark-brown py-2 rounded-md hover:bg-light-beige transition duration-300 mb-4"
        >
          SIGN IN WITH GOOGLE
        </button>
        {/* <div className="relative mb-4">
          <hr className="border-t border-gray" />
          <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white px-2 text-gray text-sm">
            or
          </span>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <input
              type="email"
              placeholder="email"
              className="w-full px-3 py-2 border border-gray rounded-md focus:outline-none focus:ring-1 focus:ring-gold"
            />
          </div>
          <div className="mb-6">
            <input
              type="password"
              placeholder="password"
              className="w-full px-3 py-2 border border-gray rounded-md focus:outline-none focus:ring-1 focus:ring-gold"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-gold text-white py-2 rounded-md hover:bg-opacity-90 transition duration-300"
          >
            LET'S GO
          </button>
        </form> */}
        <p className="mt-4 text-center text-dark-brown">
          Don't have an account?{' '}
          <Link to="/signup" className="text-gold hover:underline">
            SIGN UP
          </Link>
        </p>
      </div>
    </div>
  )
}

export default Login
