export const IntroductionStep = ({ onBegin }: { onBegin: () => void }) => (
  <div className="max-w-2xl mx-auto text-center pt-24">
    <h1 className="text-4xl font-bold text-dark-brown mb-6">
      NICE TO MEET YOU
    </h1>
    <p className="text-lg text-gray-600 mb-8">
      ready to make your matches even more
      <br />
      personal? fill out our baseline questionnaire
      <br />
      and get recommendations that are tailored
      <br />
      to your unique tastes and preferences
    </p>
    <button
      onClick={onBegin}
      className="bg-gold text-white py-2 px-6 rounded-md hover:bg-opacity-90 transition duration-300"
    >
      LET'S BEGIN
    </button>
  </div>
)
