import { Link } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import TokenWidget from '../membership/TokenWidget'

const Header = () => {
  const { user } = useAuth()

  return (
    <header className="bg-white shadow-sm">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        <Link to="/dashboard" className="text-2xl font-bold text-gold">
          GINGER
        </Link>
        <nav className="flex items-center">
          {user && <TokenWidget />}
          <ul className="flex space-x-4 ml-6">
            {!user ? (
              <>
                <li>
                  <Link to="/login" className="text-dark-brown hover:text-gold">
                    SIGN IN
                  </Link>
                </li>
                <li>
                  <Link
                    to="/signup"
                    className="bg-beige text-dark-brown px-4 py-2 rounded hover:bg-light-beige"
                  >
                    SIGN UP
                  </Link>
                </li>
              </>
            ) : (
              <li>
                <Link to="/profile" className="text-dark-brown hover:text-gold">
                  PROFILE
                </Link>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </header>
  )
}

export default Header
