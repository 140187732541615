import { useState } from 'react'
import { Link } from 'react-router-dom'
import { ExtendedMatchResult } from '../../sharedTypes'
import AuthPromptModal from '../auth/AuthPromptModal'
import { useAuth } from '../../contexts/AuthContext' // Add this import

interface MoodQuizResultProps {
  match: ExtendedMatchResult
  onNewMatch: () => void
}

const MoodQuizResult = ({ match, onNewMatch }: MoodQuizResultProps) => {
  const [selectedMatch, setSelectedMatch] = useState<'primary' | 'secondary'>(
    'primary',
  )
  const [showAuthPrompt, setShowAuthPrompt] = useState(false)
  const { user } = useAuth()

  const formatCriterion = (criterion: string): string => {
    switch (criterion) {
      case 'kidsAndPets':
        return 'kids/pets preferences'
      case 'priceRange':
        return 'price range'
      default:
        return criterion.toLowerCase()
    }
  }

  const currentMatch =
    selectedMatch === 'primary' ? match.primaryMatch : match.secondaryMatch

  // Helper function to render token information
  const renderTokenInfo = () => {
    if (!user || !currentMatch?.alreadySaved) return null

    return (
      <div className="px-6 py-4 bg-blue-50 mt-2 mb-6 rounded-lg">
        <div className="max-w-4xl mx-auto">
          <p className="text-sm text-blue-700">
            <span className="font-semibold">Note:</span> You weren't charged for
            this match since you already have the primary match saved to your
            list.
          </p>
        </div>
      </div>
    )
  }

  if (!currentMatch) return null

  return (
    <div className="max-w-4xl mx-auto p-6">
      {renderTokenInfo()}

      {/* Match Selector */}
      {match.secondaryMatch && (
        <div className="flex gap-4 mb-6">
          <button
            onClick={() => setSelectedMatch('primary')}
            className={`flex-1 p-4 rounded-lg transition-all duration-300 ${
              selectedMatch === 'primary'
                ? 'bg-gold text-white shadow-lg'
                : 'bg-light-beige text-dark-brown hover:bg-opacity-90'
            }`}
          >
            <div className="font-semibold">
              {match.primaryMatch.restaurant.name}
            </div>
            <div className="text-sm opacity-80">Primary Match</div>
          </button>

          <button
            onClick={() => setSelectedMatch('secondary')}
            className={`flex-1 p-4 rounded-lg transition-all duration-300 ${
              selectedMatch === 'secondary'
                ? 'bg-gold text-white shadow-lg'
                : 'bg-light-beige text-dark-brown hover:bg-opacity-90'
            }`}
          >
            <div className="font-semibold">
              {match.secondaryMatch.restaurant.name}
            </div>
            <div className="text-sm opacity-80">Alternative Match</div>
          </button>
        </div>
      )}

      {/* Restaurant Details Card */}
      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        {/* Image moved outside of flex container for mobile */}
        <div className="w-full h-48 md:hidden">
          <img
            className="w-full h-full object-cover"
            src={currentMatch.restaurant.imageUrl}
            alt={currentMatch.restaurant.name}
          />
        </div>

        <div className="flex flex-col md:flex-row">
          {/* Image for desktop - hidden on mobile */}
          <div className="hidden md:block md:w-1/3 relative">
            <img
              className="w-full h-full object-cover absolute inset-0"
              src={currentMatch.restaurant.imageUrl}
              alt={currentMatch.restaurant.name}
            />
          </div>

          <div className="p-6 md:w-2/3">
            <div className="uppercase tracking-wide text-m text-gold font-semibold mb-6">
              {currentMatch.restaurant.name}
            </div>

            <div className="space-y-2 text-sm">
              <p className="text-gray-600">
                Cuisine: {currentMatch.restaurant.cuisine.join(', ')}
              </p>
              <p className="text-gray-600">
                Price Range: {currentMatch.restaurant.priceRange}
              </p>
              <p className="text-gray-600">
                Neighbourhood:{' '}
                {currentMatch.restaurant.neighbourhood.join(', ')}
              </p>
              <p className="text-gray-600">
                <a
                  href={currentMatch.restaurant.website}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gold hover:underline"
                >
                  See menu
                </a>
              </p>
            </div>

            {/* Match Criteria Sections */}
            <div className="space-y-3 mt-4">
              {/* Perfect Matches */}
              {currentMatch.matchQuality.matchedCriteria.length > 0 && (
                <div className="p-3 bg-green-50 rounded-md">
                  <h3 className="text-sm font-semibold text-green-800">
                    Perfect matches:
                  </h3>
                  <p className="text-sm text-green-700">
                    {currentMatch.matchQuality.matchedCriteria
                      .map(formatCriterion)
                      .join(', ')}
                  </p>
                </div>
              )}

              {/* Flexible Criteria */}
              {currentMatch.matchQuality.relaxedCriteria.length > 0 && (
                <div className="p-3 bg-amber-50 rounded-md">
                  <h3 className="text-sm font-semibold text-amber-800">
                    Flexible with:
                  </h3>
                  <p className="text-sm text-amber-700">
                    {currentMatch.matchQuality.relaxedCriteria
                      .map(formatCriterion)
                      .join(', ')}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Action Buttons */}
      <div className="flex gap-4 justify-center mt-8">
        {user ? (
          <Link
            to="/dashboard"
            className="bg-gold text-white px-8 py-3 rounded-lg hover:bg-opacity-90 transition duration-300 font-medium"
          >
            VIEW SAVED MATCHES
          </Link>
        ) : (
          <button
            onClick={() => setShowAuthPrompt(true)}
            className="bg-gold text-white px-8 py-3 rounded-lg hover:bg-opacity-90 transition duration-300 font-medium"
          >
            SAVE MATCH
          </button>
        )}
        <button
          onClick={onNewMatch}
          className="bg-light-beige text-dark-brown px-8 py-3 rounded-lg hover:bg-opacity-90 transition duration-300 font-medium"
        >
          NEW MATCH
        </button>
      </div>
      <AuthPromptModal
        isOpen={showAuthPrompt}
        onClose={() => setShowAuthPrompt(false)}
      />
    </div>
  )
}

export default MoodQuizResult
