import React, {
  createContext,
  useContext,
  useReducer,
  ReactNode,
  useCallback,
} from 'react'
import { createPortal } from 'react-dom'
import {
  ToastType,
  Toast,
  ToastState,
  ToastContextType,
  ToastAction,
} from '../../sharedTypes'
// Create Context
const ToastContext = createContext<ToastContextType | undefined>(undefined)

// Reducer
const toastReducer = (state: ToastState, action: ToastAction): ToastState => {
  switch (action.type) {
    case 'SHOW_TOAST':
      return {
        ...state,
        toasts: [...state.toasts, action.payload],
      }
    case 'HIDE_TOAST':
      return {
        ...state,
        toasts: state.toasts.filter((toast) => toast.id !== action.payload),
      }
    default:
      return state
  }
}

// Provider Component
export const ToastProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(toastReducer, { toasts: [] })

  const showToast = useCallback((message: string, type: ToastType) => {
    const id = Math.random().toString(36).substr(2, 9)
    dispatch({
      type: 'SHOW_TOAST',
      payload: { id, message, type },
    })

    // Auto-hide after 3 seconds
    setTimeout(() => {
      dispatch({ type: 'HIDE_TOAST', payload: id })
    }, 3000)
  }, [])

  const hideToast = useCallback((id: string) => {
    dispatch({ type: 'HIDE_TOAST', payload: id })
  }, [])

  return (
    <ToastContext.Provider value={{ showToast, hideToast }}>
      {children}
      <ToastContainer toasts={state.toasts} hideToast={hideToast} />
    </ToastContext.Provider>
  )
}

// Hook for using toasts
export const useToast = () => {
  const context = useContext(ToastContext)
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider')
  }
  return context
}

// Toast Container Component
const ToastContainer = ({
  toasts,
  hideToast,
}: {
  toasts: Toast[]
  hideToast: (id: string) => void
}) => {
  if (typeof window === 'undefined') return null // Server-side rendering check

  return createPortal(
    <div className="fixed bottom-4 right-4 z-50 space-y-2">
      {toasts.map((toast) => (
        <div
          key={toast.id}
          className={`p-4 rounded-lg shadow-lg transition-all duration-300 ${getToastStyles(
            toast.type,
          )}`}
        >
          <div className="flex justify-between items-center">
            <p className="text-sm font-medium">{toast.message}</p>
            <button
              onClick={() => hideToast(toast.id)}
              className="ml-4 text-current opacity-70 hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-current rounded"
            >
              <span className="sr-only">Close</span>
              <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
      ))}
    </div>,
    document.body,
  )
}

// Helper function for toast styles
const getToastStyles = (type: ToastType): string => {
  switch (type) {
    case 'success':
      return 'bg-green-500 text-white'
    case 'error':
      return 'bg-red-500 text-white'
    case 'warning':
      return 'bg-yellow-500 text-white'
    case 'info':
      return 'bg-blue-500 text-white'
    default:
      return 'bg-gray-500 text-white'
  }
}

// Error handling utility
export const handleApiError = (
  error: unknown,
  showToast: (message: string, type: ToastType) => void,
) => {
  if (error instanceof Error) {
    if ('code' in error && typeof error.code === 'string') {
      // Assuming this is a Firebase error
      switch (error.code) {
        case 'auth/user-not-found':
          showToast('User not found. Please check your credentials.', 'error')
          break
        case 'auth/wrong-password':
          showToast('Invalid password. Please try again.', 'error')
          break
        default:
          showToast(`An error occurred: ${error.message}`, 'error')
      }
    } else {
      showToast(error.message, 'error')
    }
  } else {
    showToast('An unexpected error occurred.', 'error')
  }
}
