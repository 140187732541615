import { useState, useEffect } from 'react'
import QuizComponent from '../quiz/Quiz'
import MoodQuizResult from '../quiz/MoodQuizResult'
import { functions } from '../../utils/firebase'
import { httpsCallable } from 'firebase/functions'
import { SearchType, Match, ExtendedMatchResult } from '../../sharedTypes'
import LoadingSpinner from '../common/LoadingSpinner'
import { useLocation } from 'react-router-dom'
import { useUser } from '../../hooks/useUser'
import { useMatch } from '../../hooks/useMatch'
import { useRestaurant } from '../../hooks/useRestaurant'
import { useAuth } from '../../contexts/AuthContext'
import UpgradeOrBuyTokensModal from '../membership/UpgradeOrBuyTokensModal'
import { useToast, handleApiError } from '../common/Toast'
import { getQuizQuestions } from '../quiz/QuizQuestions'
import { useNavigate } from 'react-router-dom'

const Dashboard = () => {
  const { user: userData } = useUser()
  const { user } = useAuth()
  const [showMoodQuiz, setShowMoodQuiz] = useState(false)
  const [isQuizComplete, setIsQuizComplete] = useState(false)
  const [matchResult, setMatchResult] = useState<ExtendedMatchResult | null>(
    null,
  )
  const [searchType, setSearchType] = useState<'basic' | 'standard' | null>(
    null,
  )
  const [showUpgradeModal, setShowUpgradeModal] = useState(false)
  const { showToast } = useToast()
  const location = useLocation()
  const {
    savedMatches,
    loading: matchesLoading,
    error: matchesError,
    deleteMatch,
  } = useMatch(user?.uid ?? '')
  const [deletingMatchId, setDeletingMatchId] = useState<string | null>(null)
  const navigate = useNavigate()

  useEffect(() => {
    if (!user && location.pathname !== '/signup') {
      navigate('/signup')
    }
  }, [user, location.pathname, navigate])

  useEffect(() => {
    setShowMoodQuiz(false)
    setIsQuizComplete(false)
    setMatchResult(null)
    setSearchType(null)
  }, [location])

  const handleSearchTypeSelection = (type: SearchType) => {
    if (type === SearchType.Standard) {
      if (!user) {
        showToast('Please sign in to use specialty search', 'info')
        return
      }
      const totalTokens =
        (userData?.subscriptionTokens || 0) + (userData?.purchasedTokens || 0)
      if (totalTokens > 0) {
        setSearchType(type)
        setShowMoodQuiz(true)
      } else {
        setShowUpgradeModal(true)
      }
    } else {
      // Basic search is always allowed
      setSearchType(type)
      setShowMoodQuiz(true)
    }
  }

  const handleCloseUpgradeModal = () => {
    setShowUpgradeModal(false)
    setSearchType(null)
  }

  const handleQuizComplete = async (answers: Record<string, any>) => {
    setIsQuizComplete(true)
    console.log('Mood quiz answers:', answers)

    try {
      const findBestMatch = httpsCallable(functions, 'findBestMatch')
      const result = await findBestMatch({
        searchType: searchType,
        quizAnswers: answers,
      })

      // Instead of trying to manually refresh, let Firestore listeners handle it
      setMatchResult(result.data as ExtendedMatchResult)
    } catch (error) {
      console.error('Error finding match:', error)
      handleApiError(error, showToast)
      setMatchResult(null)
      setIsQuizComplete(false)
    }
  }

  const handleCloseQuiz = () => {
    setShowMoodQuiz(false)
    setIsQuizComplete(false)
    setMatchResult(null)
    setSearchType(null)
  }

  const handleNewMatchAfterResult = () => {
    setShowMoodQuiz(true)
    setIsQuizComplete(false)
    setMatchResult(null)
    setSearchType(null)
  }

  const handleDeleteMatch = async (matchId: string) => {
    setDeletingMatchId(matchId)
    try {
      await deleteMatch(matchId)
      showToast('Match deleted successfully', 'success')
    } catch (error) {
      showToast('Error deleting match', 'error')
    } finally {
      setDeletingMatchId(null)
    }
  }

  const renderLoadingState = () => {
    if (!isQuizComplete) return null

    return (
      <div className="fixed inset-0 bg-white bg-opacity-95 z-50 flex flex-col items-center justify-center">
        <div className="max-w-md text-center px-4">
          <h2 className="text-3xl font-bold text-dark-brown mb-6">
            Finding your perfect match
          </h2>
          <p className="text-gray-600 mb-8 text-lg">
            We're using your mood preferences to find the best restaurant for
            you right now!
          </p>
          <div className="flex justify-center">
            <LoadingSpinner size="lg" color="text-gold" />
          </div>
        </div>
      </div>
    )
  }

  const renderQuizSection = () => {
    if (showMoodQuiz) {
      if (isQuizComplete && matchResult) {
        return (
          <MoodQuizResult
            match={matchResult}
            onNewMatch={handleNewMatchAfterResult}
          />
        )
      }

      return (
        <div className="max-w-7xl mx-auto">
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="px-4 md:px-8">
              <QuizComponent
                questions={getQuizQuestions(searchType === SearchType.Standard)}
                onComplete={handleQuizComplete}
                title=""
                onClose={handleCloseQuiz}
              />
            </div>
          </div>
          {renderLoadingState()}
        </div>
      )
    }
    return null
  }

  // Render saved matches section with proper loading handling
  const renderSavedMatches = () => {
    // Only show loading spinner on initial load
    if (matchesLoading && !savedMatches.length) {
      return (
        <div className="max-w-4xl mx-auto mt-12">
          <h2 className="text-2xl font-bold text-dark-brown mb-6">
            Saved Matches
          </h2>
          <LoadingSpinner centered />
        </div>
      )
    }

    if (matchesError) {
      return (
        <div className="max-w-4xl mx-auto mt-12">
          <h2 className="text-2xl font-bold text-dark-brown mb-6">
            Saved Matches
          </h2>
          <div className="text-red-500">
            Error loading saved matches: {matchesError.message}
          </div>
        </div>
      )
    }

    if (!savedMatches.length) {
      return null
    }

    return (
      <div className="max-w-4xl mx-auto mt-12">
        <h2 className="text-2xl font-bold text-dark-brown mb-6">
          Saved Matches
        </h2>
        <div className="space-y-6">
          {savedMatches.map((match) => (
            <SavedMatchItem
              key={match.id}
              match={match}
              onDelete={handleDeleteMatch}
              isDeleting={deletingMatchId === match.id}
            />
          ))}
        </div>
      </div>
    )
  }

  const shouldShowUnauthenticatedDashboard = () => {
    if (!user) return true
    if (matchesLoading) return false
    return savedMatches.length === 0
  }

  return (
    <>
      <div className="max-w-7xl mx-auto">
        {!showMoodQuiz && (
          <>
            {/* Hero Section */}
            <div className="bg-white shadow-lg rounded-lg overflow-hidden">
              <div className="relative h-[180px] sm:h-[180px]">
                <img
                  src="/images/ginger-dashboard-test.png"
                  alt="Dashboard"
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent" />

                <div className="absolute inset-0 flex flex-col justify-end p-4 sm:p-8">
                  <div className="mb-4">
                    <h2 className="text-2xl sm:text-3xl font-bold text-white mb-1">
                      ready to find a match?
                    </h2>
                    <p className="text-xl text-gold font-bold hidden sm:block">
                      let me help you
                    </p>
                  </div>

                  <div className="flex flex-col sm:flex-row gap-2 sm:gap-4">
                    <button
                      onClick={() =>
                        handleSearchTypeSelection(SearchType.Standard)
                      }
                      className="w-full sm:w-auto bg-gold text-white px-4 py-2 rounded-md hover:bg-opacity-90 transition duration-300 relative group"
                    >
                      <div className="flex flex-col items-center">
                        <span>SPECIALTY SEARCH</span>
                      </div>
                    </button>
                    <button
                      onClick={() =>
                        handleSearchTypeSelection(SearchType.Basic)
                      }
                      className="w-full sm:w-auto bg-gold text-white px-4 py-2 rounded-md hover:bg-opacity-90 transition duration-300"
                    >
                      STANDARD SEARCH
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* Content Section */}
            <div className="mt-4 sm:mt-8">
              {shouldShowUnauthenticatedDashboard() && (
                <UnauthenticatedDashboard />
              )}
            </div>

            {/* Saved Matches Section */}
            {user && renderSavedMatches()}
          </>
        )}

        {renderQuizSection()}
      </div>

      <UpgradeOrBuyTokensModal
        isOpen={showUpgradeModal}
        onClose={handleCloseUpgradeModal}
      />
    </>
  )
}

// Modified UnauthenticatedDashboard to handle both cases
const UnauthenticatedDashboard = () => {
  const { user } = useAuth()

  return (
    <div className="max-w-4xl mx-auto mt-8">
      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="pt-1 pb-4 px-4 sm:pt-4 sm:px-6 flex flex-col items-center text-center">
          <h3 className="text-xl font-semibold text-dark-brown mb-2">
            {user ? 'Start Your Collection' : 'Start Building Your Collection'}
          </h3>
          <p className="text-gray-600 max-w-md">
            Choose <span className="font-semibold">Standard Search</span> for
            quick matches, or try{' '}
            <span className="font-semibold">Specialty Search</span> for our
            extended restaurant database with curated specialty filters.{' '}
            {user
              ? "Your matches will appear here once you've completed your first search."
              : 'Your personalised collection of matches will appear below after you complete your first search and sign up.'}
          </p>
          <div className="mt-8 w-full max-w-lg">
            <div className="border-2 border-dashed border-light-beige rounded-lg p-6">
              <div className="flex items-center gap-4">
                <div className="w-20 h-20 bg-light-beige/30 rounded-md flex items-center justify-center"></div>
                <div className="flex-1">
                  <div className="h-4 w-32 bg-light-beige/30 rounded mb-2"></div>
                  <div className="h-3 w-48 bg-light-beige/30 rounded"></div>
                </div>
              </div>
            </div>
            <div className="mt-4 border-2 border-dashed border-light-beige rounded-lg p-6 opacity-60">
              <div className="flex items-center gap-4">
                <div className="w-20 h-20 bg-light-beige/30 rounded-md flex items-center justify-center"></div>
                <div className="flex-1">
                  <div className="h-4 w-32 bg-light-beige/30 rounded mb-2"></div>
                  <div className="h-3 w-48 bg-light-beige/30 rounded"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
// SavedMatchItem component
const SavedMatchItem = ({
  match,
  onDelete,
  isDeleting,
}: {
  match: Match
  onDelete: (id: string) => Promise<void>
  isDeleting: boolean
}) => {
  const [expanded, setExpanded] = useState(false)
  const { restaurant, loading, error } = useRestaurant(match.restaurantId)

  if (loading) return <LoadingSpinner centered />
  if (error) return <div>Error loading restaurant: {error.message}</div>
  if (!restaurant) return null

  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="flex flex-col md:flex-row">
        <div className="h-40 md:h-auto md:w-1/3 relative overflow-hidden">
          <img
            className="w-full h-full object-cover absolute inset-0"
            src={restaurant.imageUrl}
            alt={restaurant.name}
          />
        </div>
        <div className="p-6 md:w-2/3 flex flex-col justify-between">
          <div>
            <div className="flex justify-between items-start">
              <div>
                <div className="uppercase tracking-wide text-sm text-gold font-semibold">
                  {restaurant.name}
                </div>
                <p className="mt-1 text-gray-500">
                  {restaurant.cuisine.join(', ')} cuisine
                </p>
              </div>
              <button
                onClick={() => setExpanded(!expanded)}
                className="bg-gold text-white px-3 py-1 rounded-md hover:bg-opacity-90 transition duration-300 text-sm"
              >
                {expanded ? 'Less' : 'More'}
              </button>
            </div>
            <p className="mt-2 text-gray-600 line-clamp-2">
              {restaurant.description ||
                'A cozy place with an extensive selection of dishes. Perfect for a relaxed evening out.'}
            </p>
            {expanded && (
              <div className="mt-4 text-sm text-gray-600">
                <p>Price Range: {restaurant.priceRange}</p>
                <p>Neighbourhood: {restaurant.neighbourhood}</p>
                <p>Setting: {restaurant.setting?.join(', ')}</p>
                <div className="mt-4">
                  <button
                    onClick={() => onDelete(match.id)}
                    disabled={isDeleting}
                    className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-opacity-90 transition duration-300 text-sm disabled:opacity-50"
                  >
                    {isDeleting ? 'Deleting...' : 'Delete'}
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="mt-4 flex justify-between items-center">
            <a
              href={restaurant.website}
              target="_blank"
              rel="noopener noreferrer"
              className="text-gold hover:underline"
            >
              See menu
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
