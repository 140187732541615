import { Question } from "../../sharedTypes";

export const QuestionnaireData: Question[] = [
  {
    id: "dietType",
    title: "DIET TYPE",
    subtitle: "what's your lifestyle",
    type: "multipleChoice",
    options: [
      "Vegetarian",
      "Vegan",
      "Pescatarian",
      "Flexitarian",
      "Keto",
      "Paleo",
      "No restrictions",
      "Other",
    ],
    required: false,
  },
  {
    id: "spiceLevel",
    title: "SPICE TOLERANCE",
    subtitle: "how hot can you handle",
    type: "multipleChoice",
    options: ["Mild", "Medium", "Spicy", "Extra spicy", "I breathe fire"],
    required: true,
  },
  {
    id: "diningPreference",
    title: "DINING PREFERENCE",
    subtitle: "your ideal restaurant experience",
    type: "multipleChoice",
    options: [
      "Fast casual",
      "Casual dining",
      "Fine dining",
      "Buffet",
      "Food trucks",
      "Cafes",
    ],
    required: true,
  },
  {
    id: "mealPreference",
    title: "MEAL PREFERENCE",
    subtitle: "when do you dine out most",
    type: "multipleChoice",
    options: ["Breakfast", "Brunch", "Lunch", "Dinner", "Late night"],
    required: true,
  },
  {
    id: "priceRange",
    title: "PRICE RANGE",
    subtitle: "whats your budget for a meal",
    type: "multipleChoice",
    options: [
      "$   (Under $15)",
      "$$  ($15 - $30)",
      "$$$ ($30 - $50)",
      "$$$$ (Over $50)",
    ],
    required: true,
  },
  {
    id: "atmospherePreference",
    title: "ATMOSPHERE",
    subtitle: "your preferred dining ambiance",
    type: "multipleChoice",
    options: [
      "Quiet and intimate",
      "Lively and social",
      "Family-friendly",
      "Outdoor seating",
      "Trendy and modern",
      "Classic and traditional",
    ],
    required: true,
  },
  {
    id: "cuisinePreferences",
    title: "CUISINE PREFERENCES",
    subtitle: "what flavors excite your palate",
    type: "checkbox",
    options: [
      "Italian",
      "Japanese",
      "Mexican",
      "Chinese",
      "Indian",
      "Thai",
      "French",
      "Mediterranean",
      "American",
      "Korean",
    ],
    required: true,
  },
  {
    id: "dietaryRestrictions",
    title: "DIETARY RESTRICTIONS",
    subtitle: "any specific needs we should know",
    type: "checkbox",
    options: [
      "Gluten-free",
      "Dairy-free",
      "Nut allergy",
      "Shellfish allergy",
      "Halal",
      "Kosher",
      "Low-sodium",
      "None",
    ],
    required: true,
  },
];
