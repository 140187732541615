import { DocumentReference, Timestamp } from "firebase/firestore";
import { GeoPoint } from "firebase/firestore";

export interface User {
  userId: string;
  email: string;
  membershipType: "free" | "paid";
  subscriptionTokens: number;
  purchasedTokens: number;
  tokenRenewalDate: Timestamp;
  subscriptionStartDate: Timestamp;
  dietaryPreferences: string[];
  cuisinePreferences: string[];
  lastLoginDate: Timestamp;
  questionnaires: DocumentReference[];
  stripeCustomerId?: string;
  stripeSubscriptionId?: string;
}

export interface Questionnaire {
  id: string;
  title: string;
  description: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  isActive: boolean;
  questions: Question[];
}

export interface Question {
  id: string;
  title: string;
  subtitle: string;
  type: "text" | "multipleChoice" | "checkbox" | "scale";
  options?: string[];
  required: boolean;
}

export interface QuestionnaireResponse {
  userId: DocumentReference;
  completionDate: Timestamp;
  lastUpdated: Timestamp;
  answers: {
    [questionId: string]: any;
  };
}

export interface Restaurant {
  id?: string;
  name: string;
  description: string;
  website: string;
  days: string[];
  meal: string[];
  cuisine: string[];
  priceRange: string[];
  neighbourhood: string[];
  atmosphere?: string;
  setting?: string[];
  kidsAndPets?: string[];
  cardPayment?: boolean;
  accessibility?: boolean;
  restrictions?: string;
  diet?: string;
  delivery?: boolean;
  highProtein?: boolean;
  specialtyDrinks?: string;
  wifi?: boolean;
  location?: GeoPoint | { latitude: number; longitude: number };
  averageRating?: number;
  imageUrl?: string;
  searchType?: SearchType;
}

export interface RecentRecommendations {
  userId: string;
  recommendations: RecommendationEntry[];
}

export interface RecommendationEntry {
  restaurantId: string;
  timestamp: Timestamp;
}

export interface Match {
  id: string;
  userId: string;
  restaurantId: string;
  matchDate: Date | Timestamp;
  saved: boolean;
  aiReasoning?: string;
  userRating?: number;
  userReview?: string;
}

export enum SearchType {
  Basic = "basic",
  Standard = "standard",
}

export const MATCH_CRITERIA = [
  "meal",
  "cuisine",
  "priceRange",
  "neighbourhood",
  "setting",
  "kidsAndPets",
  "accessibility",
] as const;

export type MatchCriterion = (typeof MATCH_CRITERIA)[number];

export interface MatchResult {
  restaurant: Restaurant;
  matchQuality: {
    matchedCriteria: MatchCriterion[];
    relaxedCriteria: MatchCriterion[];
  };
  alreadySaved?: boolean;
}

export interface ExtendedMatchResult {
  primaryMatch: MatchResult;
  secondaryMatch: MatchResult | null;
}

export interface MoodQuizResultProps {
  match: ExtendedMatchResult;
  onNewMatch: () => void;
}

export interface QuizProps {
  questions: Question[];
  onComplete: (answers: Record<string, any>) => void;
  title: string;
  onClose: () => void;
}

export interface UpgradeOrBuyTokensModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export interface LoadingSpinnerProps {
  size?: "sm" | "md" | "lg";
  color?: string;
  centered?: boolean;
}

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "secondary";
  children: React.ReactNode;
}

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error?: string;
}

export interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
}

export type ToastType = "success" | "error" | "info" | "warning";

export interface Toast {
  id: string;
  message: string;
  type: ToastType;
}

export interface ToastState {
  toasts: Toast[];
}

export interface ToastContextType {
  showToast: (message: string, type: ToastType) => void;
  hideToast: (id: string) => void;
}

export type ToastAction =
  | { type: "SHOW_TOAST"; payload: Toast }
  | { type: "HIDE_TOAST"; payload: string };

export interface UpdateUserRequest {
  updates: {
    dietaryPreferences?: string[];
    cuisinePreferences?: string[];
    [key: string]: any; // For other potential updates
  };
}

export interface UpdateUserResponse {
  success: boolean;
  message: string;
}
