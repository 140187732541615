import { useState, useEffect, useCallback } from "react";
import { db, functions } from "../utils/firebase";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { Match } from "../sharedTypes";
import { logMatchDelete, logMatchSave } from "../utils/analytics";

export function useMatch(userId: string) {
  const [savedMatches, setSavedMatches] = useState<Match[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!userId) return;

    setLoading(true);
    const matchesRef = collection(db, "matches");
    const q = query(
      matchesRef,
      where("userId", "==", userId),
      where("saved", "==", true)
    );

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const matches = snapshot.docs.map(
          (doc) =>
            ({
              id: doc.id,
              ...doc.data(),
            } as Match)
        );
        setSavedMatches(matches);
        setLoading(false);
      },
      (err) => {
        console.error("Error fetching saved matches:", err);
        setError(
          err instanceof Error ? err : new Error("An unknown error occurred")
        );
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [userId]);

  const saveMatch = useCallback(
    async (restaurantId: string) => {
      try {
        const saveMatchFunction = httpsCallable(functions, "saveMatch");
        await saveMatchFunction({ restaurantId });
        logMatchSave(userId, restaurantId);
        // The saved match will be added to the list automatically via the onSnapshot listener
      } catch (err) {
        console.error("Error saving match:", err);
        throw err instanceof Error
          ? err
          : new Error("An unknown error occurred while saving the match");
      }
    },
    [userId]
  );

  const deleteMatch = useCallback(
    async (matchId: string) => {
      try {
        const deleteMatchFunction = httpsCallable(
          functions,
          "deleteSavedMatch"
        );
        await deleteMatchFunction({ matchId });
        logMatchDelete(userId, matchId);

        // The match will be removed from the list automatically via the onSnapshot listener
      } catch (err) {
        console.error("Error deleting match:", err);
        throw err instanceof Error
          ? err
          : new Error("An unknown error occurred while deleting the match");
      }
    },
    [userId]
  );

  return { savedMatches, loading, error, saveMatch, deleteMatch };
}
