import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUser } from '../../hooks/useUser'
import { User } from '../../sharedTypes'
import LoadingSpinner from '../common/LoadingSpinner'
import { QuestionnaireData } from './QuestionnaireData'
import { IntroductionStep } from './IntroductionStep'
import { EndStep } from './EndStep'
import { QuestionStep } from './QuestionStep'

const Questionnaire = () => {
  const [currentStep, setCurrentStep] = useState(-1)
  const [answers, setAnswers] = useState<Record<string, string | string[]>>({})
  const [isCompleted, setIsCompleted] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const navigate = useNavigate()
  const { updateUser } = useUser()

  const handleBegin = () => {
    setCurrentStep(0)
  }

  const handleAnswer = (answer: string | string[]) => {
    setAnswers({ ...answers, [QuestionnaireData[currentStep].id]: answer })
    // Auto advance for single choice questions
    if (QuestionnaireData[currentStep].type === 'multipleChoice') {
      if (currentStep < QuestionnaireData.length - 1) {
        setTimeout(() => setCurrentStep(currentStep + 1), 300)
      } else {
        handleSubmit()
      }
    }
  }

  const handleNext = () => {
    if (currentStep < QuestionnaireData.length - 1) {
      setCurrentStep(currentStep + 1)
    } else {
      handleSubmit()
    }
  }

  const handleSkip = () => {
    // If skipping a question, ensure we don't save any partial answers
    const currentQuestionId = QuestionnaireData[currentStep].id
    const updatedAnswers = { ...answers }
    delete updatedAnswers[currentQuestionId]
    setAnswers(updatedAnswers)
    handleNext()
  }

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1)
    } else if (currentStep === 0) {
      setCurrentStep(-1)
    } else {
      navigate(-1)
    }
  }

  const handleSubmit = async () => {
    setIsSubmitting(true)
    try {
      const updates = {
        dietaryPreferences: (answers['dietaryRestrictions'] as string[]) || [],
        cuisinePreferences: (answers['cuisinePreferences'] as string[]) || [],
      }
      await updateUser({
        ...(updates as Partial<User>),
      })
      setIsCompleted(true)
    } catch (error) {
      console.error('Error submitting questionnaire:', error)
    } finally {
      setIsSubmitting(false)
    }
  }

  const renderActionButton = () => {
    // Only show action button for checkbox questions
    if (QuestionnaireData[currentStep]?.type !== 'checkbox') return null

    const isLastStep = currentStep === QuestionnaireData.length - 1
    const onClick = isLastStep ? handleSubmit : handleNext
    const text = isLastStep ? 'FINISH' : 'NEXT'

    return (
      <button
        onClick={onClick}
        disabled={isSubmitting}
        className="px-6 py-2 bg-gold text-white rounded-md hover:bg-opacity-90 transition duration-300"
      >
        {isSubmitting ? (
          <span className="flex items-center">
            <LoadingSpinner size="sm" color="white" />
          </span>
        ) : (
          text
        )}
      </button>
    )
  }

  const handleFinish = () => {
    navigate('/dashboard')
  }

  if (isCompleted) {
    return <EndStep onFinish={handleFinish} />
  }

  return (
    <div className="flex flex-col justify-center">
      {currentStep === -1 ? (
        <IntroductionStep onBegin={handleBegin} />
      ) : (
        <>
          <QuestionStep
            question={QuestionnaireData[currentStep]}
            onAnswer={handleAnswer}
            onAutoAdvance={
              QuestionnaireData[currentStep].type === 'multipleChoice'
                ? handleNext
                : undefined
            }
            onNext={handleNext}
            onSkip={
              !QuestionnaireData[currentStep].required ? handleSkip : undefined
            }
            currentStep={currentStep + 1} // Add this line (adding 1 since we start at 0)
            totalSteps={QuestionnaireData.length} // Add this line
          />
          <div className="flex justify-center mt-8 space-x-4">
            <button
              onClick={handleBack}
              className="px-6 py-2 bg-light-beige text-dark-brown rounded-md hover:bg-opacity-90 transition duration-300"
            >
              BACK
            </button>
            {renderActionButton()}
            {!QuestionnaireData[currentStep].required && (
              <button
                onClick={handleSkip}
                className="px-6 py-2 bg-gray-100 text-gray-600 rounded-md hover:bg-gray-200 transition duration-300 flex items-center"
              >
                <span className="mr-2">Skip</span>
                <svg
                  className="w-4 h-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </button>
            )}
          </div>
          <div className="mt-8 flex justify-center">
            {QuestionnaireData.map((_, index) => (
              <div
                key={index}
                className={`w-3 h-3 rounded-full mx-1 ${
                  index === currentStep ? 'bg-gold' : 'bg-light-beige'
                }`}
              />
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default Questionnaire
