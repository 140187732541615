export const EndStep = ({ onFinish }: { onFinish: () => void }) => (
  <div className="flex flex-col items-center justify-center bg-white text-center px-4 pt-24">
    <h2 className="text-dark-brown text-6xl font-bold mb-8">ALL SET</h2>
    <p className="text-gray-500 text-xl max-w-md mb-12">
      we're excited to start matching you with places that are perfectly suited
      to your tastes. get ready to discover your new favorite spots!
    </p>
    <button
      onClick={onFinish}
      className="bg-gold text-white font-bold py-3 px-8 rounded-md hover:bg-opacity-90 transition duration-300"
    >
      GO TO DASHBOARD
    </button>
  </div>
)
