import { useState, useEffect, useCallback } from "react";
import { db, functions } from "../utils/firebase";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { Timestamp } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { User } from "../sharedTypes";
import { useAuth } from "../contexts/AuthContext";

export function useUser() {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(false); // Start with false
  const [error, setError] = useState<Error | null>(null);
  const { user: authUser } = useAuth();

  const fetchUser = useCallback(async () => {
    if (!authUser) {
      setUser(null);
      setLoading(false);
      return;
    }

    setLoading(true);
    setError(null);

    try {
      console.log("Fetching user data for:", authUser.uid);
      const userDoc = await getDoc(doc(db, "users", authUser.uid));

      if (!userDoc.exists()) {
        console.log("No user document found");
        setUser(null);
        return;
      }

      const data = userDoc.data();
      console.log("User data fetched:", data);
      setUser({
        userId: authUser.uid,
        ...data,
        tokenRenewalDate: data.tokenRenewalDate as Timestamp,
        lastLoginDate: data.lastLoginDate as Timestamp,
        dietaryPreferences: data.dietaryPreferences || [],
        cuisinePreferences: data.cuisinePreferences || [],
      } as User);
    } catch (err) {
      console.error("Error fetching user data:", err);
      setError(
        err instanceof Error
          ? err
          : new Error("An error occurred while fetching user data")
      );
    } finally {
      setLoading(false);
    }
  }, [authUser]);

  const updateUser = useCallback(
    async (updates: Partial<User>): Promise<void> => {
      if (!authUser) {
        throw new Error("No authenticated user");
      }

      try {
        await authUser.getIdToken(true);

        console.log("Sending updates to updateUser function:", updates);
        const updateUserFunction = httpsCallable(functions, "updateUser");

        const result = await updateUserFunction({ updates });
        console.log("Update result:", result.data);

        await fetchUser();
      } catch (error) {
        console.error("Error updating user:", error);
        if (error instanceof Error) {
          if (
            error.message.includes("unauthenticated") ||
            error.message.includes("auth/")
          ) {
            throw new Error("Authentication error. Please sign in again.");
          }
        }
        throw error;
      }
    },
    [authUser, fetchUser]
  );

  const refreshUser = useCallback(async () => {
    await fetchUser();
  }, [fetchUser]);

  useEffect(() => {
    if (!authUser?.uid) {
      setUser(null);
      setLoading(false);
      return;
    }

    // Set loading true only when we're actually going to fetch data
    setLoading(true);

    const userRef = doc(db, "users", authUser.uid);

    const unsubscribe = onSnapshot(
      userRef,
      (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          setUser({
            userId: doc.id,
            ...data,
            tokenRenewalDate: data.tokenRenewalDate as Timestamp,
            lastLoginDate: data.lastLoginDate as Timestamp,
            dietaryPreferences: data.dietaryPreferences || [],
            cuisinePreferences: data.cuisinePreferences || [],
          } as User);
        } else {
          setUser(null);
        }
        setLoading(false);
      },
      (err) => {
        console.error("Error fetching user data:", err);
        setError(
          err instanceof Error ? err : new Error("Unknown error occurred")
        );
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [authUser?.uid]);

  return {
    user,
    loading,
    error,
    updateUser,
    refreshUser,
  };
}
