import { useState, useEffect } from 'react'
import { Question } from '../../sharedTypes'
import LoadingSpinner from '../common/LoadingSpinner'
import { QuestionStep } from '../questionnaire/QuestionStep'

interface QuizProps {
  questions: Question[]
  onComplete: (answers: Record<string, any>) => void
  title: string
  onClose: () => void
}

const QuizComponent = ({
  questions,
  onComplete,
  title,
  onClose,
}: QuizProps) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [answers, setAnswers] = useState<Record<string, any>>({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [attempted, setAttempted] = useState(false)

  useEffect(() => {
    const initialAnswers: Record<string, any> = {}
    questions.forEach((question) => {
      initialAnswers[question.id] =
        question.type === 'checkbox' ? [] : undefined
    })
    setAnswers((prev) => ({ ...initialAnswers, ...prev }))
  }, [questions])

  const handleAnswer = (answer: string | string[]) => {
    setAnswers((prev) => ({ ...prev, [questions[currentStep].id]: answer }))
    setError(null)
    setAttempted(false)
  }

  const handleAutoAdvance = () => {
    // Use a small timeout to ensure state is updated
    setTimeout(() => {
      if (currentStep < questions.length - 1) {
        setCurrentStep((prev) => prev + 1)
        setError(null)
        setAttempted(false)
      } else {
        handleSubmit()
      }
    }, 50)
  }

  const validateCurrentStep = (): boolean => {
    const currentQuestion = questions[currentStep]
    const currentAnswer = answers[currentQuestion.id]

    if (currentQuestion.required) {
      if (currentQuestion.type === 'checkbox') {
        if (!currentAnswer || currentAnswer.length === 0) {
          setError('Please select at least one option to continue')
          setAttempted(true)
          return false
        }
      } else if (!currentAnswer) {
        setError('Please select an option to continue')
        setAttempted(true)
        return false
      }
    }
    return true
  }

  const handleNext = () => {
    if (!validateCurrentStep()) return

    if (currentStep < questions.length - 1) {
      setCurrentStep((prev) => prev + 1)
      setError(null)
      setAttempted(false)
    } else {
      handleSubmit()
    }
  }

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1)
      setError(null)
      setAttempted(false)
    } else {
      onClose()
    }
  }

  const handleSubmit = async () => {
    if (!validateCurrentStep()) return

    setIsSubmitting(true)
    try {
      await onComplete(answers)
    } catch (error) {
      console.error('Error submitting quiz:', error)
      setError('Failed to submit answers. Please try again.')
    } finally {
      setIsSubmitting(false)
    }
  }

  const currentQuestion = questions[currentStep]
  const isMultiSelect = currentQuestion.type === 'checkbox'

  return (
    <div className="max-w-2xl mx-auto text-center flex flex-col">
      <QuestionStep
        question={currentQuestion}
        onAnswer={handleAnswer}
        onAutoAdvance={!isMultiSelect ? handleAutoAdvance : undefined}
        error={error}
        attempted={attempted}
        selectedAnswer={answers[currentQuestion.id]}
        onNext={handleNext}
        onBack={handleBack}
        currentStep={currentStep}
        totalSteps={questions.length}
      />
    </div>
  )
}

export default QuizComponent
