import { logAnalyticsEvent } from "./firebase";

export const logMatchRequest = (userId: string, preferences: object) => {
  logAnalyticsEvent("match_request", {
    userId,
    preferences,
  });
};

export const logMatchSave = (userId: string, restaurantId: string) => {
  logAnalyticsEvent("match_save", {
    userId,
    restaurantId,
  });
};

export const logMatchDelete = (userId: string, matchId: string) => {
  logAnalyticsEvent("match_delete", {
    userId,
    matchId,
  });
};

export const logMatchReview = (
  userId: string,
  restaurantId: string,
  rating: number
) => {
  logAnalyticsEvent("match_review", {
    userId,
    restaurantId,
    rating,
  });
};

export const logUpgrade = (userId: string, planType: string) => {
  logAnalyticsEvent("user_upgrade", {
    userId,
    planType,
  });
};
