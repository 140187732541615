import { useState } from 'react'
import { useUser } from '../../hooks/useUser'
import UpgradeOrBuyTokensModal from './UpgradeOrBuyTokensModal'

const TokenWidget = () => {
  const { user, loading, error } = useUser()
  const [isHovered, setIsHovered] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    console.error('Error in TokenWidget:', error)
    return null
  }

  if (!user) {
    console.log('TokenWidget has no user')
    return null
  }

  const totalTokens =
    (user.subscriptionTokens || 0) + (user.purchasedTokens || 0)

  const getBackgroundColor = () => {
    if (totalTokens <= 3 && totalTokens > 0)
      return 'bg-amber-500 text-white hover:bg-amber-600'
    return 'bg-light-beige text-dark-brown hover:bg-gold hover:text-white'
  }

  return (
    <>
      <button
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => setIsModalOpen(true)}
        className={`flex items-center rounded-full px-3 py-1 transition duration-300 ${getBackgroundColor()}`}
      >
        <span className="mr-2 text-xl" role="img" aria-label="Token">
          🫚
        </span>
        <span className="font-semibold">
          {isHovered ? 'Buy Tokens' : `${totalTokens || 0} Tokens`}
        </span>
      </button>
      <UpgradeOrBuyTokensModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  )
}

export default TokenWidget
