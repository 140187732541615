import React, { useState, useEffect } from 'react'
import { collection, getDocs } from 'firebase/firestore'
import { db } from '../../utils/firebase'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
} from 'recharts'
import { Restaurant, Match, User } from '../../sharedTypes'

const COLORS = {
  gold: '#BF8E2F',
  beige: '#C1B197',
  darkBrown: '#4D321F',
  lightBeige: '#DCD6D0',
  gray: '#B3ABA8',
}

const CACHE_KEY = 'ginger_analytics_cache'
const CACHE_DURATION = 100 * 60 * 1000 // 100 minutes

interface MetricState {
  totalUsers: number
  activeUsers: number
  premiumUsers: number
  totalMatches: number
  savedMatches: number
  totalRestaurants: number
  weeklyMetrics: Array<{
    week: string
    matches: number
    newUsers: number
  }>
  subscriptionDistribution: Array<{ name: string; value: number }>
  matchDistribution: Array<{ name: string; value: number }>
  restaurantDistribution: Array<{ name: string; value: number }>
  neighborhoodDistribution: Array<{ name: string; value: number }>
  cuisineDistribution: Array<{ name: string; value: number }>
  conversionFunnel: Array<{ name: string; value: number }>
}

const MetricCard = ({
  title,
  value,
  subtitle,
}: {
  title: string
  value: number
  subtitle?: string
}) => (
  <div className="bg-white rounded-lg shadow-md p-4 border border-light-beige">
    <h3 className="text-sm font-medium text-gray">{title}</h3>
    <p className="text-2xl font-bold text-gold mt-1">
      {value.toLocaleString()}
    </p>
    {subtitle && <p className="text-xs text-gray mt-1">{subtitle}</p>}
  </div>
)

const ChartCard = ({
  title,
  children,
}: {
  title: string
  children: React.ReactNode
}) => (
  <div className="bg-white rounded-lg shadow-md p-4 border border-light-beige">
    <h3 className="text-sm font-medium text-gold mb-4">{title}</h3>
    {children}
  </div>
)

export default function AnalyticsDashboard() {
  const [metrics, setMetrics] = useState<MetricState>({
    totalUsers: 0,
    activeUsers: 0,
    premiumUsers: 0,
    totalMatches: 0,
    savedMatches: 0,
    totalRestaurants: 0,
    weeklyMetrics: [],
    subscriptionDistribution: [],
    matchDistribution: [],
    restaurantDistribution: [],
    neighborhoodDistribution: [],
    cuisineDistribution: [],
    conversionFunnel: [],
  })
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)

      // Check cache
      const cached = localStorage.getItem(CACHE_KEY)
      if (cached) {
        const { data, timestamp } = JSON.parse(cached)
        if (Date.now() - timestamp < CACHE_DURATION) {
          setMetrics(data)
          setIsLoading(false)
          return
        }
      }

      // Fetch data
      const usersSnapshot = await getDocs(collection(db, 'users'))
      const matchesSnapshot = await getDocs(collection(db, 'matches'))
      const restaurantsSnapshot = await getDocs(collection(db, 'restaurants'))

      const users = usersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...(doc.data() as Omit<User, 'id'>),
      }))
      const matches = matchesSnapshot.docs.map(
        (doc) => ({ id: doc.id, ...doc.data() } as Match),
      )
      const restaurants = restaurantsSnapshot.docs.map(
        (doc) => ({ id: doc.id, ...doc.data() } as Restaurant),
      )

      // Calculate metrics
      const newMetrics: MetricState = {
        totalUsers: users.length,
        activeUsers: users.filter((u) => u.lastLoginDate).length,
        premiumUsers: users.filter((u) => u.membershipType === 'paid').length,
        totalMatches: matches.length,
        savedMatches: matches.filter((m) => m.saved).length,
        totalRestaurants: restaurants.length,
        weeklyMetrics: calculateWeeklyMetrics(matches, users),
        subscriptionDistribution: [
          {
            name: 'Free',
            value: users.filter((u) => u.membershipType === 'free').length,
          },
          {
            name: 'Premium',
            value: users.filter((u) => u.membershipType === 'paid').length,
          },
        ],
        matchDistribution: [
          { name: 'Saved', value: matches.filter((m) => m.saved).length },
          { name: 'Not Saved', value: matches.filter((m) => !m.saved).length },
        ],
        restaurantDistribution: calculateRestaurantDistribution(restaurants),
        neighborhoodDistribution: calculateNeighborhoodDistribution(
          restaurants,
        ),
        cuisineDistribution: calculateCuisineDistribution(restaurants),
        conversionFunnel: [
          { name: 'Total Users', value: users.length },
          {
            name: 'Active Users',
            value: users.filter((u) => u.lastLoginDate).length,
          },
          {
            name: 'Premium Users',
            value: users.filter((u) => u.membershipType === 'paid').length,
          },
        ],
      }

      // Cache the data
      localStorage.setItem(
        CACHE_KEY,
        JSON.stringify({ data: newMetrics, timestamp: Date.now() }),
      )

      setMetrics(newMetrics)
      setIsLoading(false)
    }

    fetchData()
  }, [])

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-pulse text-gold">Loading analytics...</div>
      </div>
    )
  }

  return (
    <div className="p-6 max-w-7xl mx-auto">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-dark-brown">
          Analytics Dashboard
        </h1>
        <button
          onClick={() => {
            localStorage.removeItem(CACHE_KEY)
            window.location.reload()
          }}
          className="px-4 py-2 bg-gold text-white rounded-lg hover:bg-opacity-90 transition-colors"
        >
          Refresh Data
        </button>
      </div>

      {/* Key Metrics Grid */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
        <MetricCard title="Total Users" value={metrics.totalUsers} />
        <MetricCard
          title="Premium Users"
          value={metrics.premiumUsers}
          subtitle={`${(
            (metrics.premiumUsers / metrics.totalUsers) *
            100
          ).toFixed(1)}% conversion`}
        />
        <MetricCard title="Total Matches" value={metrics.totalMatches} />
      </div>

      {/* Charts Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Weekly Metrics */}
        <ChartCard title="Weekly Activity">
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={metrics.weeklyMetrics}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="week" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="matches"
                name="Matches"
                stroke={COLORS.gold}
              />
              <Line
                type="monotone"
                dataKey="newUsers"
                name="New Users"
                stroke={COLORS.beige}
              />
            </LineChart>
          </ResponsiveContainer>
        </ChartCard>

        {/* Subscription Distribution */}
        <ChartCard title="User Subscription Distribution">
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={metrics.subscriptionDistribution}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={100}
                label
              >
                {metrics.subscriptionDistribution.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={Object.values(COLORS)[index]}
                  />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </ChartCard>

        {/* Restaurant Distribution by Neighborhood */}
        <ChartCard title="Restaurants by Neighborhood">
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={metrics.neighborhoodDistribution}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="value" fill={COLORS.gold} />
            </BarChart>
          </ResponsiveContainer>
        </ChartCard>

        {/* Cuisine Distribution */}
        <ChartCard title="Restaurant Cuisine Distribution">
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={metrics.cuisineDistribution}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={100}
                label
              >
                {metrics.cuisineDistribution.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={
                      Object.values(COLORS)[
                        index % Object.values(COLORS).length
                      ]
                    }
                  />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </ChartCard>
      </div>
    </div>
  )
}

// Helper functions
function calculateWeeklyMetrics(matches: Match[], users: User[]) {
  const weeklyData: Record<string, { matches: number; newUsers: number }> = {}

  // Process matches
  matches.forEach((match) => {
    if (match.matchDate) {
      const week = new Date(
        'toDate' in match.matchDate
          ? match.matchDate.toDate()
          : match.matchDate,
      )
        .toISOString()
        .slice(0, 10)
      if (!weeklyData[week]) weeklyData[week] = { matches: 0, newUsers: 0 }
      weeklyData[week].matches++
    }
  })

  // Process users
  users.forEach((user) => {
    if (user.subscriptionStartDate) {
      const week = new Date(user.subscriptionStartDate.toDate())
        .toISOString()
        .slice(0, 10)
      if (!weeklyData[week]) weeklyData[week] = { matches: 0, newUsers: 0 }
      weeklyData[week].newUsers++
    }
  })

  return Object.entries(weeklyData)
    .map(([week, data]) => ({ week, ...data }))
    .sort((a, b) => a.week.localeCompare(b.week))
}

function calculateRestaurantDistribution(restaurants: Restaurant[]) {
  const distribution: Record<string, number> = {}
  restaurants.forEach((restaurant) => {
    restaurant.cuisine.forEach((cuisine) => {
      distribution[cuisine] = (distribution[cuisine] || 0) + 1
    })
  })
  return Object.entries(distribution)
    .map(([name, value]) => ({ name, value }))
    .sort((a, b) => b.value - a.value)
}

function calculateNeighborhoodDistribution(restaurants: Restaurant[]) {
  const distribution: Record<string, number> = {}
  restaurants.forEach((restaurant) => {
    restaurant.neighbourhood.forEach((hood) => {
      distribution[hood] = (distribution[hood] || 0) + 1
    })
  })
  return Object.entries(distribution)
    .map(([name, value]) => ({ name, value }))
    .sort((a, b) => b.value - a.value)
}

function calculateCuisineDistribution(restaurants: Restaurant[]) {
  const distribution: Record<string, number> = {}
  restaurants.forEach((restaurant) => {
    restaurant.cuisine.forEach((cuisine) => {
      distribution[cuisine] = (distribution[cuisine] || 0) + 1
    })
  })
  return Object.entries(distribution)
    .map(([name, value]) => ({ name, value }))
    .sort((a, b) => b.value - a.value)
    .slice(0, 8) // Show top 8 cuisines
}
