import { Link } from 'react-router-dom'

const AuthPromptModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) => {
  if (!isOpen) return null

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold text-dark-brown">
            Save Your Match
          </h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
            aria-label="Close modal"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <p className="text-gray-600 mb-6">
          Create an account or sign in to save matches and build your
          personalized list of favorite spots.
        </p>

        <div className="space-y-4">
          <Link
            to="/signup"
            className="block w-full bg-gold text-white text-center py-2 rounded-md hover:bg-opacity-90 transition duration-300"
            onClick={onClose}
          >
            SIGN UP
          </Link>

          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white text-gray-500">or</span>
            </div>
          </div>

          <Link
            to="/login"
            className="block w-full bg-light-beige text-dark-brown text-center py-2 rounded-md hover:bg-opacity-90 transition duration-300"
            onClick={onClose}
          >
            SIGN IN
          </Link>
        </div>
      </div>
    </div>
  )
}

export default AuthPromptModal
