import { useState, useEffect, useCallback } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../utils/firebase";
import { Restaurant } from "../../../shared/types";

export function useRestaurant(restaurantId: string | null) {
  const [restaurant, setRestaurant] = useState<Restaurant | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchRestaurant = useCallback(async () => {
    if (!restaurantId) {
      setRestaurant(null);
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const restaurantDoc = await getDoc(doc(db, "restaurants", restaurantId));

      if (!restaurantDoc.exists()) {
        setRestaurant(null);
        return;
      }

      setRestaurant({
        id: restaurantDoc.id,
        ...restaurantDoc.data(),
      } as Restaurant);
    } catch (err) {
      setError(
        err instanceof Error
          ? err
          : new Error("An error occurred while fetching restaurant data")
      );
    } finally {
      setLoading(false);
    }
  }, [restaurantId]);

  useEffect(() => {
    fetchRestaurant();
  }, [fetchRestaurant]);

  return { restaurant, loading, error, refreshRestaurant: fetchRestaurant };
}
