import React, { useState } from 'react'
import { useUser } from '../../hooks/useUser'
import { Link } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import UpgradeOrBuyTokensModal from '../membership/UpgradeOrBuyTokensModal'
import { useToast, handleApiError } from '../common/Toast'
import LoadingSpinner from '../common/LoadingSpinner'

const Profile = () => {
  const { user: authUser } = useAuth()
  const { logout } = useAuth()
  const { user, loading, error, updateUser } = useUser()
  const { showToast } = useToast()
  const navigate = useNavigate()

  const [isEditing, setIsEditing] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [preferences, setPreferences] = useState({
    dietaryPreferences: [] as string[],
    cuisinePreferences: [] as string[],
  })

  if (!authUser) return <div>Please log in to view your profile.</div>
  if (loading) return <div>Loading...</div>
  if (error) return <div>Error: {error.message}</div>
  if (!user) return <div>Please log in to view your profile.</div>

  const handlePreferenceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = e.target
    setPreferences((prev) => ({
      ...prev,
      [name]: checked
        ? [...prev[name as keyof typeof preferences], value]
        : prev[name as keyof typeof preferences].filter(
            (item) => item !== value,
          ),
    }))
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setIsLoading(true)
    try {
      await updateUser(preferences)
      showToast('Preferences updated successfully!', 'success')
      setIsEditing(false)
    } catch (error) {
      console.error('Error updating preferences:', error)
      handleApiError(error, showToast)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSignOut = async () => {
    try {
      await logout()
      navigate('/dashboard')
    } catch (error) {
      console.error('Error signing out:', error)
      alert('Failed to sign out. Please try again.')
    }
  }

  return (
    <>
      <div className="flex items-center justify-center flex-grow p-6">
        <div className="w-full max-w-4xl">
          <h1 className="text-3xl font-bold text-dark-brown mb-6">
            My Account
          </h1>

          <div className="bg-white shadow rounded-lg mb-6 p-6">
            <h2 className="text-xl font-semibold mb-4">Account Details</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <p className="text-sm text-gray-500">Email</p>
                <p className="font-medium">{user.email}</p>
              </div>
              <div>
                <p className="text-sm text-gray-500">Membership Type</p>
                <p className="font-medium capitalize">{user.membershipType}</p>
              </div>
              <div>
                <p className="text-sm text-gray-500">Subscription Tokens</p>
                <p className="font-medium">{user.subscriptionTokens || 0}</p>
              </div>
              <div>
                <p className="text-sm text-gray-500">Purchased Tokens</p>
                <p className="font-medium">{user.purchasedTokens || 0}</p>
              </div>
              <div>
                <p className="text-sm text-gray-500">Token Renewal Date</p>
                <p className="font-medium">
                  {user.tokenRenewalDate && user.tokenRenewalDate.toDate
                    ? user.tokenRenewalDate.toDate().toLocaleDateString()
                    : 'Not available'}
                </p>
              </div>
            </div>
          </div>

          <div className="bg-white shadow rounded-lg mb-6 p-6">
            <h2 className="text-xl font-semibold mb-4">My Preferences</h2>
            {isEditing ? (
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <p className="font-medium mb-2">Dietary Preferences</p>
                  {[
                    'Vegetarian',
                    'Vegan',
                    'Gluten-free',
                    'Dairy-free',
                    'Kosher',
                    'Halal',
                  ].map((pref) => (
                    <label key={pref} className="flex items-center mt-2">
                      <input
                        type="checkbox"
                        name="dietaryPreferences"
                        value={pref}
                        checked={preferences.dietaryPreferences.includes(pref)}
                        onChange={handlePreferenceChange}
                        className="form-checkbox h-5 w-5 text-gold"
                      />
                      <span className="ml-2">{pref}</span>
                    </label>
                  ))}
                </div>
                <div className="mb-4">
                  <p className="font-medium mb-2">Cuisine Preferences</p>
                  {[
                    'Italian',
                    'Chinese',
                    'Mexican',
                    'Japanese',
                    'Indian',
                    'Thai',
                  ].map((cuisine) => (
                    <label key={cuisine} className="flex items-center mt-2">
                      <input
                        type="checkbox"
                        name="cuisinePreferences"
                        value={cuisine}
                        checked={preferences.cuisinePreferences.includes(
                          cuisine,
                        )}
                        onChange={handlePreferenceChange}
                        className="form-checkbox h-5 w-5 text-gold"
                      />
                      <span className="ml-2">{cuisine}</span>
                    </label>
                  ))}
                </div>
                <div className="flex justify-end space-x-4">
                  <button
                    type="button"
                    onClick={() => setIsEditing(false)}
                    className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
                    disabled={isLoading}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="px-6 py-2 bg-gold text-white rounded-md hover:bg-opacity-90 transition duration-300 flex items-center justify-center"
                  >
                    {isLoading ? (
                      <span className="flex items-center">
                        <LoadingSpinner size="sm" color="text-white" />
                      </span>
                    ) : (
                      'Save Changes'
                    )}
                  </button>
                </div>
              </form>
            ) : (
              <div>
                <div className="mb-4">
                  <p className="font-medium">Dietary Preferences:</p>
                  <p>
                    {user.dietaryPreferences.join(', ') || 'None specified'}
                  </p>
                </div>
                <div className="mb-4">
                  <p className="font-medium">Cuisine Preferences:</p>
                  <p>
                    {user.cuisinePreferences.join(', ') || 'None specified'}
                  </p>
                </div>
                <button
                  onClick={() => setIsEditing(true)}
                  className="px-4 py-2 bg-gold text-white rounded-md hover:bg-opacity-90"
                >
                  Edit Preferences
                </button>
              </div>
            )}
          </div>

          <div className="bg-white shadow rounded-lg p-6">
            <h2 className="text-xl font-semibold mb-4">
              {user.membershipType === 'free'
                ? 'Upgrade Your Membership or Buy Tokens'
                : 'Buy Tokens'}
            </h2>
            <p className="mb-4">
              {user.membershipType === 'free'
                ? 'Upgrade to our premium membership for more tokens and enhanced features, or buy individual tokens!'
                : 'Purchase additional tokens for your account.'}
            </p>
            <button
              onClick={() => setIsModalOpen(true)}
              className="bg-gold hover:bg-opacity-90 text-white font-bold py-2 px-4 rounded"
            >
              {user.membershipType === 'free'
                ? 'Upgrade or Buy Tokens'
                : 'Buy Tokens'}
            </button>
          </div>
          <div className="bg-white shadow rounded-lg p-6 mt-6">
            <h2 className="text-xl font-semibold mb-4">Need Help?</h2>
            <p>
              Check out the FAQ section at the bottom of our{' '}
              <Link
                to="https://gingerlist.com/"
                className="text-gold hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                website
              </Link>{' '}
              for answers to common questions or email us at hey@gingerlist.com
            </p>
          </div>
          {/* Sign Out Button */}
          <div className="mt-6">
            <button
              onClick={handleSignOut}
              className="w-full bg-red-500 text-white py-2 rounded-md hover:bg-red-600 transition duration-300"
            >
              SIGN OUT
            </button>
          </div>
        </div>
      </div>
      <UpgradeOrBuyTokensModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  )
}

export default Profile
